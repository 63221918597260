'use client';

import { motion, useAnimation } from 'motion/react';
import Image, { StaticImageData } from 'next/image';
import React, { useRef } from 'react';

import BaseSpline from '@/assets/chain-spline/base.png';
import ModeSpline from '@/assets/chain-spline/mode.png';
import OptimismSpline from '@/assets/chain-spline/optimism.png';
import PolygonSpline from '@/assets/chain-spline/polygon.png';
import ZorbSpline from '@/assets/chain-spline/zorb.png';
import BaseIcon from '@/assets/chains/base.svg';
import ModeIcon from '@/assets/chains/mode.svg';
import OptimismIcon from '@/assets/chains/optimism.svg';
import PolygonIcon from '@/assets/chains/polygon.svg';
import ZorbImage from '@/assets/chains/zorb.png';

type ChainSupportedWrapperProps = {
  items: IconItemProps[];
};

function ChainSupportedWrapper({ items }: ChainSupportedWrapperProps) {
  return (
    <section className="container-alt bg-clay-texture m-20 -mt-32 flex flex-col items-center justify-between gap-4 p-7 shadow md:flex-row">
      <div className="flex-shrink-0">
        <h3 className="font-display text-primary whitespace-nowrap text-lg md:w-1/2">
          supported chains
        </h3>
      </div>

      {/* divider */}
      <div className="hidden h-[1px] flex-grow bg-gray-600 md:block" />

      {/* right */}
      <div className="flex max-w-md flex-col items-center gap-8 md:items-end">
        <p className="text-md w-full text-center tracking-tight md:text-left">
          native support for all major EVM-compatible networks powering the
          onchain economy
        </p>

        {/* chain icons */}
        <div className="grid grid-cols-3 items-center gap-8 md:grid-cols-6">
          {items.map((item, index) => (
            <IconItem
              key={index}
              icon={item.icon}
              number={item.number}
              hoverImageSrc={item.hoverImageSrc}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

type IconItemProps = {
  icon: React.ReactNode;
  number: number;
  hoverImageSrc: StaticImageData | string;
};

const iconItems: IconItemProps[] = [
  {
    icon: <BaseIcon className="h-full w-full" />,
    number: 8453,
    hoverImageSrc: BaseSpline,
  },
  {
    icon: <OptimismIcon className="h-full w-full" />,
    number: 10,
    hoverImageSrc: OptimismSpline,
  },
  {
    icon: <ModeIcon className="h-full w-full" />,
    number: 34443,
    hoverImageSrc: ModeSpline,
  },
  {
    icon: <PolygonIcon className="h-full w-full" />,
    number: 137,
    hoverImageSrc: PolygonSpline,
  },
  {
    icon: (
      <Image
        src={ZorbImage}
        alt="zorb chain"
        width={32}
        height={32}
        className="h-full w-full"
      />
    ),
    number: 7777777,
    hoverImageSrc: ZorbSpline,
  },
];

export function ChainsSupported() {
  return <ChainSupportedWrapper items={iconItems} />;
}

function IconItem({ icon, number, hoverImageSrc }: IconItemProps) {
  const controls = useAnimation();
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!containerRef.current) return;

    const bounds = containerRef.current.getBoundingClientRect();
    const mouseX = event.clientX - bounds.left;
    const mouseY = event.clientY - bounds.top;

    // calculate the center point of the container
    const centerX = bounds.width / 2;
    const centerY = bounds.height / 2;

    // calculate the offset from center and apply sensitivity multiplier
    const sensitivityMultiplier = 3; // increase this value for more sensitive movement
    const offsetX = (mouseX - centerX) * sensitivityMultiplier;
    const offsetY = (mouseY - centerY) * sensitivityMultiplier;

    controls.start({
      x: centerX + offsetX - 150, // Center the image horizontally (300/2)
      y: centerY + offsetY - 50, // Center the image vertically (100/2)
      scale: 7,
      opacity: 1,
      transition: {
        type: 'spring',
        damping: 20,
        stiffness: 200,
      },
    });
  };

  const handleMouseLeave = () => {
    controls.start({
      scale: 0.95,
      opacity: 0,
      transition: {
        duration: 0.3,
        ease: 'easeOut',
      },
    });
  };

  return (
    <div
      ref={containerRef}
      className="transform-style-3d perspective-1000 group relative flex flex-col items-center gap-2"
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      {/* IconGroup wrapper */}
      <div className="flex flex-col items-center rounded-lg p-3 transition-colors">
        <div className="flex h-8 w-8 items-center justify-center">
          <div className="h-6">{icon}</div>
        </div>
        <span className="mt-2 text-xs">{number}</span>
      </div>

      {/* Hover Image */}
      <motion.div
        className="pointer-events-none absolute z-50"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={controls}
        style={{
          originX: 0.5,
          originY: 0.5,
        }}
      >
        <Image
          src={hoverImageSrc}
          alt="hover preview"
          width={300}
          height={100}
          className="transition-all duration-300 ease-out"
        />
      </motion.div>
    </div>
  );
}
